@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-display: fallback;
  src:
    local('Inter-Regular'),
    url('ev-font/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src:
    local('Inter-SemiBold'),
    url('ev-font/Inter-SemiBold.ttf') format('truetype');
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
